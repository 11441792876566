import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthGuard } from '@/auth/guard';
import SettingLayout from '@/layouts/setting';
import { LoadingScreen } from '@/components/loading-screen';
import { retry } from '@/utils/common';
import AdminGuard from '@/auth/guard/admin-guard';

const LogListPage = lazy(
  () =>
    retry(() => import('@/pages/setting/logs/list')) as Promise<
      typeof import('@/pages/setting/logs/list')
    >
);
const LogDetailPage = lazy(
  () =>
    retry(() => import('@/pages/setting/logs/detail')) as Promise<
      typeof import('@/pages/setting/logs/detail')
    >
);

const CountryListPage = lazy(
  () =>
    retry(() => import('@/pages/setting/countries/list')) as Promise<
      typeof import('@/pages/setting/countries/list')
    >
);
const CountryEditPage = lazy(
  () =>
    retry(() => import('@/pages/setting/countries/edit')) as Promise<
      typeof import('@/pages/setting/countries/edit')
    >
);
const CountryCreatePage = lazy(
  () =>
    retry(() => import('@/pages/setting/countries/create')) as Promise<
      typeof import('@/pages/setting/countries/create')
    >
);
const IndustryListPage = lazy(
  () =>
    retry(() => import('@/pages/setting/industries/list')) as Promise<
      typeof import('@/pages/setting/industries/list')
    >
);
const IndustryEditPage = lazy(
  () =>
    retry(() => import('@/pages/setting/industries/edit')) as Promise<
      typeof import('@/pages/setting/industries/edit')
    >
);
const IndustryCreatePage = lazy(
  () =>
    retry(() => import('@/pages/setting/industries/create')) as Promise<
      typeof import('@/pages/setting/industries/create')
    >
);
const SegmentListPage = lazy(
  () =>
    retry(() => import('@/pages/setting/segments/list')) as Promise<
      typeof import('@/pages/setting/segments/list')
    >
);
const SegmentEditPage = lazy(
  () =>
    retry(() => import('@/pages/setting/segments/edit')) as Promise<
      typeof import('@/pages/setting/segments/edit')
    >
);
const SegmentCreatePage = lazy(
  () =>
    retry(() => import('@/pages/setting/segments/create')) as Promise<
      typeof import('@/pages/setting/segments/create')
    >
);
const LifeCycleListPage = lazy(
  () =>
    retry(() => import('@/pages/setting/life-cycles/list')) as Promise<
      typeof import('@/pages/setting/life-cycles/list')
    >
);
const LifeCycleEditPage = lazy(
  () =>
    retry(() => import('@/pages/setting/life-cycles/edit')) as Promise<
      typeof import('@/pages/setting/life-cycles/edit')
    >
);
const LifeCycleCreatePage = lazy(
  () =>
    retry(() => import('@/pages/setting/life-cycles/create')) as Promise<
      typeof import('@/pages/setting/life-cycles/create')
    >
);
const ChurnReasonListPage = lazy(
  () =>
    retry(() => import('@/pages/setting/churn-reasons/list')) as Promise<
      typeof import('@/pages/setting/churn-reasons/list')
    >
);
const ChurnReasonEditPage = lazy(
  () =>
    retry(() => import('@/pages/setting/churn-reasons/edit')) as Promise<
      typeof import('@/pages/setting/churn-reasons/edit')
    >
);
const ChurnReasonCreatePage = lazy(
  () =>
    retry(() => import('@/pages/setting/churn-reasons/create')) as Promise<
      typeof import('@/pages/setting/churn-reasons/create')
    >
);
const ProductTypeListPage = lazy(
  () =>
    retry(() => import('@/pages/setting/product-types/list')) as Promise<
      typeof import('@/pages/setting/product-types/list')
    >
);
const ProductTypeEditPage = lazy(
  () =>
    retry(() => import('@/pages/setting/product-types/edit')) as Promise<
      typeof import('@/pages/setting/product-types/edit')
    >
);
const ProductTypeCreatePage = lazy(
  () =>
    retry(() => import('@/pages/setting/product-types/create')) as Promise<
      typeof import('@/pages/setting/product-types/create')
    >
);
const BusinessUnitListPage = lazy(
  () =>
    retry(() => import('@/pages/setting/business-unit/list')) as Promise<
      typeof import('@/pages/setting/business-unit/list')
    >
);
const BusinessUnitEditPage = lazy(
  () =>
    retry(() => import('@/pages/setting/business-unit/edit')) as Promise<
      typeof import('@/pages/setting/business-unit/edit')
    >
);
const BusinessUnitCreatePage = lazy(
  () =>
    retry(() => import('@/pages/setting/business-unit/create')) as Promise<
      typeof import('@/pages/setting/business-unit/create')
    >
);
const BusinessLineListPage = lazy(
  () =>
    retry(() => import('@/pages/setting/business-lines/list')) as Promise<
      typeof import('@/pages/setting/business-lines/list')
    >
);
const BusinessLineEditPage = lazy(
  () =>
    retry(() => import('@/pages/setting/business-lines/edit')) as Promise<
      typeof import('@/pages/setting/business-lines/edit')
    >
);
const BusinessLineCreatePage = lazy(
  () =>
    retry(() => import('@/pages/setting/business-lines/create')) as Promise<
      typeof import('@/pages/setting/business-lines/create')
    >
);
const ProductLineListPage = lazy(
  () =>
    retry(() => import('@/pages/setting/product-lines/list')) as Promise<
      typeof import('@/pages/setting/product-lines/list')
    >
);
const ProductLineEditPage = lazy(
  () =>
    retry(() => import('@/pages/setting/product-lines/edit')) as Promise<
      typeof import('@/pages/setting/product-lines/edit')
    >
);
const ProductLineCreatePage = lazy(
  () =>
    retry(() => import('@/pages/setting/product-lines/create')) as Promise<
      typeof import('@/pages/setting/product-lines/create')
    >
);
const StudioListPage = lazy(
  () =>
    retry(() => import('@/pages/setting/studios/list')) as Promise<
      typeof import('@/pages/setting/studios/list')
    >
);
const StudioEditPage = lazy(
  () =>
    retry(() => import('@/pages/setting/studios/edit')) as Promise<
      typeof import('@/pages/setting/studios/edit')
    >
);
const StudioCreatePage = lazy(
  () =>
    retry(() => import('@/pages/setting/studios/create')) as Promise<
      typeof import('@/pages/setting/studios/create')
    >
);
const TermConditionListPage = lazy(
  () =>
    retry(() => import('@/pages/setting/term-conditions/list')) as Promise<
      typeof import('@/pages/setting/term-conditions/list')
    >
);
const TermConditionEditPage = lazy(
  () =>
    retry(() => import('@/pages/setting/term-conditions/edit')) as Promise<
      typeof import('@/pages/setting/term-conditions/edit')
    >
);
const TermConditionCreatePage = lazy(
  () =>
    retry(() => import('@/pages/setting/term-conditions/create')) as Promise<
      typeof import('@/pages/setting/term-conditions/create')
    >
);
const ExternalityReasonsListPage = lazy(
  () =>
    retry(() => import('@/pages/setting/externality-reasons/list')) as Promise<
      typeof import('@/pages/setting/externality-reasons/list')
    >
);
const ExternalityReasonsEditPage = lazy(
  () =>
    retry(() => import('@/pages/setting/externality-reasons/edit')) as Promise<
      typeof import('@/pages/setting/externality-reasons/edit')
    >
);
const ExternalityReasonsCreatePage = lazy(
  () =>
    retry(() => import('@/pages/setting/externality-reasons/create')) as Promise<
      typeof import('@/pages/setting/externality-reasons/create')
    >
);
const CurrencyListPage = lazy(
  () =>
    retry(() => import('@/pages/setting/currencies/list')) as Promise<
      typeof import('@/pages/setting/currencies/list')
    >
);
const CurrencyEditPage = lazy(
  () =>
    retry(() => import('@/pages/setting/currencies/edit')) as Promise<
      typeof import('@/pages/setting/currencies/edit')
    >
);
const CurrencyCreatePage = lazy(
  () =>
    retry(() => import('@/pages/setting/currencies/create')) as Promise<
      typeof import('@/pages/setting/currencies/create')
    >
);
const DocumentTypeListPage = lazy(
  () =>
    retry(() => import('@/pages/setting/document-types/list')) as Promise<
      typeof import('@/pages/setting/document-types/list')
    >
);
const DocumentTypeEditPage = lazy(
  () =>
    retry(() => import('@/pages/setting/document-types/edit')) as Promise<
      typeof import('@/pages/setting/document-types/edit')
    >
);
const DocumentTypeCreatePage = lazy(
  () =>
    retry(() => import('@/pages/setting/document-types/create')) as Promise<
      typeof import('@/pages/setting/document-types/create')
    >
);
const ContactTypeListPage = lazy(
  () =>
    retry(() => import('@/pages/setting/contact-types/list')) as Promise<
      typeof import('@/pages/setting/contact-types/list')
    >
);
const ContactTypeEditPage = lazy(
  () =>
    retry(() => import('@/pages/setting/contact-types/edit')) as Promise<
      typeof import('@/pages/setting/contact-types/edit')
    >
);
const ContactTypeCreatePage = lazy(
  () =>
    retry(() => import('@/pages/setting/contact-types/create')) as Promise<
      typeof import('@/pages/setting/contact-types/create')
    >
);
const ApprovalFlowListPage = lazy(
  () =>
    retry(() => import('@/pages/setting/approval-flows/list')) as Promise<
      typeof import('@/pages/setting/approval-flows/list')
    >
);
const ApprovalFlowEditPage = lazy(
  () =>
    retry(() => import('@/pages/setting/approval-flows/edit')) as Promise<
      typeof import('@/pages/setting/approval-flows/edit')
    >
);
const ApprovalFlowCreatePage = lazy(
  () =>
    retry(() => import('@/pages/setting/approval-flows/create')) as Promise<
      typeof import('@/pages/setting/approval-flows/create')
    >
);
const AFNewUserListPage = lazy(
  () =>
    retry(() => import('@/pages/setting/af-new-user/list')) as Promise<
      typeof import('@/pages/setting/af-new-user/list')
    >
);
const AFNewUserEditPage = lazy(
  () =>
    retry(() => import('@/pages/setting/af-new-user/edit')) as Promise<
      typeof import('@/pages/setting/af-new-user/edit')
    >
);
const AFNewUserCreatePage = lazy(
  () =>
    retry(() => import('@/pages/setting/af-new-user/create')) as Promise<
      typeof import('@/pages/setting/af-new-user/create')
    >
);

const IndexPage = lazy(() => import('@/pages/dashboard/one'));

export const settingRoutes = [
  {
    path: 'settings',
    element: (
      <AuthGuard>
        <SettingLayout>
          <AdminGuard hasContent>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </AdminGuard>
        </SettingLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: 'general',
        children: [
          {
            path: 'countries',
            children: [
              { element: <CountryListPage />, index: true },
              { path: 'list', element: <CountryListPage /> },
              { path: ':id/edit', element: <CountryEditPage /> },
              { path: 'new', element: <CountryCreatePage /> },
            ],
          },
          {
            path: 'currencies',
            children: [
              { element: <CurrencyListPage />, index: true },
              { path: 'list', element: <CurrencyListPage /> },
              { path: ':id/edit', element: <CurrencyEditPage /> },
              { path: 'new', element: <CurrencyCreatePage /> },
            ],
          },
        ],
      },
      {
        path: 'accounts',
        children: [
          {
            path: 'companies',
            children: [
              { element: <IndustryListPage />, index: true },
              { path: 'list', element: <IndustryListPage /> },
              { path: ':id/edit', element: <IndustryEditPage /> },
              { path: 'new', element: <IndustryCreatePage /> },
              {
                path: 'segments',
                children: [
                  { element: <SegmentListPage />, index: true },
                  { path: 'list', element: <SegmentListPage /> },
                  { path: ':id/edit', element: <SegmentEditPage /> },
                  { path: 'new', element: <SegmentCreatePage /> },
                ],
              },
              {
                path: 'life-cycles',
                children: [
                  { element: <LifeCycleListPage />, index: true },
                  { path: 'list', element: <LifeCycleListPage /> },
                  { path: 'new', element: <LifeCycleCreatePage /> },
                  { path: ':id/edit', element: <LifeCycleEditPage /> },
                ],
              },
              {
                path: 'churn-reasons',
                children: [
                  { element: <ChurnReasonListPage />, index: true },
                  { path: 'list', element: <ChurnReasonListPage /> },
                  { path: 'new', element: <ChurnReasonCreatePage /> },
                  { path: ':id/edit', element: <ChurnReasonEditPage /> },
                ],
              },
              {
                path: 'document-types',
                children: [
                  { element: <DocumentTypeListPage />, index: true },
                  { path: 'list', element: <DocumentTypeListPage /> },
                  { path: 'new', element: <DocumentTypeCreatePage /> },
                  { path: ':id/edit', element: <DocumentTypeEditPage /> },
                ],
              },
              {
                path: 'contact-types',
                children: [
                  { element: <ContactTypeListPage />, index: true },
                  { path: 'list', element: <ContactTypeListPage /> },
                  { path: 'new', element: <ContactTypeCreatePage /> },
                  { path: ':id/edit', element: <ContactTypeEditPage /> },
                ],
              },
            ],
          },
          {
            path: 'orders',
            children: [
              { element: <ApprovalFlowListPage />, index: true },
              {
                path: 'approval-flow',
                children: [
                  { element: <ApprovalFlowListPage />, index: true },
                  { path: 'list', element: <ApprovalFlowListPage /> },
                  { path: 'new', element: <ApprovalFlowCreatePage /> },
                  { path: ':id/edit', element: <ApprovalFlowEditPage /> },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'approval-flow',
        children: [
          {
            path: 'orders',
            children: [
              { element: <ApprovalFlowListPage />, index: true },
              { path: 'list', element: <ApprovalFlowListPage /> },
              { path: ':id/edit', element: <ApprovalFlowEditPage /> },
              { path: 'new', element: <ApprovalFlowCreatePage /> },
            ],
          },
          {
            path: 'new-user',
            children: [
              { element: <AFNewUserListPage />, index: true },
              { path: 'list', element: <AFNewUserListPage /> },
              { path: ':id/edit', element: <AFNewUserEditPage /> },
              { path: 'new', element: <AFNewUserCreatePage /> },
            ],
          },
        ],
      },

      {
        path: 'portfolio',
        children: [
          {
            path: 'general',
            children: [
              { element: <ProductTypeListPage />, index: true },
              { path: 'list', element: <ProductTypeListPage /> },
              { path: ':id/edit', element: <ProductTypeEditPage /> },
              { path: 'new', element: <ProductTypeCreatePage /> },
              {
                path: 'business-units',
                children: [
                  { element: <BusinessUnitListPage />, index: true },
                  { path: 'list', element: <BusinessUnitListPage /> },
                  { path: 'new', element: <BusinessUnitCreatePage /> },
                  { path: ':id/edit', element: <BusinessUnitEditPage /> },
                ],
              },
              {
                path: 'business-lines',
                children: [
                  { element: <BusinessLineListPage />, index: true },
                  { path: 'list', element: <BusinessLineListPage /> },
                  { path: ':id/edit', element: <BusinessLineEditPage /> },
                  { path: 'new', element: <BusinessLineCreatePage /> },
                ],
              },
              {
                path: 'product-lines',
                children: [
                  { element: <ProductLineListPage />, index: true },
                  { path: 'list', element: <ProductLineListPage /> },
                  { path: 'new', element: <ProductLineCreatePage /> },
                  { path: ':id/edit', element: <ProductLineEditPage /> },
                ],
              },
              {
                path: 'studios',
                children: [
                  { element: <StudioListPage />, index: true },
                  { path: 'list', element: <StudioListPage /> },
                  { path: 'new', element: <StudioCreatePage /> },
                  { path: ':id/edit', element: <StudioEditPage /> },
                ],
              },
              {
                path: 'term-conditions',
                children: [
                  { element: <TermConditionListPage />, index: true },
                  { path: 'list', element: <TermConditionListPage /> },
                  { path: 'new', element: <TermConditionCreatePage /> },
                  { path: ':id/edit', element: <TermConditionEditPage /> },
                ],
              },
              {
                path: 'externality-reasons',
                children: [
                  { element: <ExternalityReasonsListPage />, index: true },
                  { path: 'list', element: <ExternalityReasonsListPage /> },
                  { path: 'new', element: <ExternalityReasonsCreatePage /> },
                  { path: ':id/edit', element: <ExternalityReasonsEditPage /> },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'finances',
        children: [
          {
            path: 'payments',
            children: [
              { element: <IndexPage />, index: true },
              { path: 'list', element: <IndexPage /> },
              { path: ':id/edit', element: <IndexPage /> },
              { path: 'new', element: <IndexPage /> },
            ],
          },
        ],
      },
      {
        path: 'access',
        children: [
          {
            path: 'logs',
            children: [
              { element: <LogListPage />, index: true },
              { path: 'list', element: <LogListPage /> },
              { path: ':id', element: <LogDetailPage /> },
              { path: 'new', element: <IndexPage /> },
            ],
          },
        ],
      },
    ],
  },
];
